import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography, Button, styled, IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { GlobalContext } from 'context';
import { makeStyles } from 'tss-react/mui';
import ArrowClose from 'components/local/icons/ArrowClose';
import ArrowExpand from 'components/local/icons/ArrowExpand';
import Set from 'components/local/icons/Set';
import Buyr from 'components/local/icons/Buyr';
import SupIcon from 'components/local/icons/SuppIcon';
import logo from 'public/svg/upartLogo.svg';
import { logout } from '/context/actions/auth';
import { LogOut as LogoutIcon } from 'react-feather';
import ReorderIcon from '@mui/icons-material/Reorder';
import clsx from 'clsx';

//icons
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const drawerWidth = 332;
const closedDrawerWidth = 56;
const drawerColor = '#373A46';

const useStyles = makeStyles()(() => {
    return {
        root: {
            flex: 'none',
            width: 260,
            backgroundColor: '#f6f6f6',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 60
        },
        drawerPaper: {
            '&&': {
                backgroundColor: '#f6f6f6'
            }
        },
        drawerButton: {
            marginTop: 20,
            alignSelf: 'flex-start',
            color: '#FFF',
            width: 40,
            height: 40,
            marginLeft: 8
        },
        innerDrawerContainer: {
            padding: 46,
            paddingTop: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        hideInnerDrawer: {
            display: 'none'
        },
        title: {
            userSelect: 'none',
            padding: '3px 0px 0px 12px',
            fontWeight: 400,
            '&:hover': {
                color: 'black'
            }
        },
        child: {
            paddingLeft: 10,
            '& .MuiTypography-h4': {
                fontSize: '12px'
            }
        },
        icon: {
            width: '25px',
            height: '24px',
            backgroundRepeat: 'no-repeat',
            marginTop: -3
        },
        menu: {
            margin: '8px 0px',
            cursor: 'pointer',
            display: 'flex',
            width: '220px'
        },
        logo: {
            width: 69,
            height: 80,
            backgroundImage: `url(${logo.src})`,
            backgroundSize: 'cover',
            marginBottom: 50
        }
    };
});

export default function LeftMenu() {
    const { state, dispatch } = useContext(GlobalContext);
    const { classes } = useStyles();
    const router = useRouter();
    const [open, setOpen] = useState(true);
    // const [open, setOpen] = useState('supplier');
    const [menu, setMenu] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('');

    useEffect(() => {
        if (state.auth.loggedIn) setMenu(state.auth.menu);
    }, [state.auth]);

    useEffect(() => {
        setSelectedMenu(state.system.selectedMenu);
    }, [state.system.selectedMenu]);

    const handleClick = (href, item) => {
        // setOpen(href);
        if (!item.children || item.children.length == 0) {
            router.push('/' + item.url, null, { shallow: true });
            localStorage.setItem('action', JSON.stringify(item.actions));
        } else updateState(item.id, menu);
    };

    const handleLogout = async () => {
        await logout(dispatch);
        if (state.auth.loggedIn) router.push('/login');
    };

    const updateState = (id, tree) => {
        tree.map((item) => {
            if (item.id === id) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.hasOwnProperty('isExpanded')) {
                    if (item.isExpanded) item.isExpanded = false;
                    else item.isExpanded = true;
                } else item['isExpanded'] = true;
            }
            if (item.children) {
                if (item.children.length > 0) {
                    updateState(id, item.children);
                }
            }
        });
        setMenu([...menu]);
    };

    const handleDrawerButtonClick = () => {
        setOpen(!open);
    };

    const renderTree = (item, type) => {
        // if (type === 'parent') updateState(item.id, menu);
        return (
            <Box
                key={item.id}
                className={type === 'parent' ? classes.parent : classes.child}
                // onClick={(e) => handleClick(item.url, item)}
            >
                <Box
                    margin="8px 0px"
                    className={classes.menu}
                    title={item.name}
                    onClick={() => handleClick(item.url, item)}>
                    <Box className={classes.icon}>
                        {item.icon === 'settings' ? (
                            <Set />
                        ) : item.icon === 'buyer' ? (
                            <AccessibilityIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'request' ? (
                            <AssignmentIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'order' ? (
                            <Buyr sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'withdraw' ? (
                            <FileDownloadIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'roles' ? (
                            <ManageAccountsIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'settingsMenu' ? (
                            <WidgetsIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'warehouse' ? (
                            <WarehouseIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'confirmWarehouse' ? (
                            <DoneIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'confirmOrderFinish' ? (
                            <DoneAllIcon sx={{ color: '#FCC12A' }} />
                        ) : item.icon === 'supplier' ? (
                            <SupIcon />
                        ) : type === 'parent' ? (
                            <Buyr />
                        ) : (
                            ''
                        )}
                    </Box>
                    <Typography
                        variant="h4"
                        className={classes.title}
                        style={{
                            fontWeight: item.url === selectedMenu ? '700' : '400',
                            color: item.url === selectedMenu ? 'black' : '#797979'
                        }}>
                        {item.name}
                    </Typography>
                    {item.children ? (
                        item.isExpanded === true && item.children.length ? (
                            <ArrowExpand />
                        ) : item.children.length > 0 ? (
                            <ArrowClose />
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                </Box>
                {Array.isArray(item.children) && item.isExpanded
                    ? item.children.map((node) => renderTree(node, 'child'))
                    : null}
            </Box>
        );
    };

    return (
        <CustomDrawer
            variant="permanent"
            open={open}
            classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
            <IconButton className={classes.drawerButton} onClick={handleDrawerButtonClick}>
                <ReorderIcon style={{ color: '#656565' }} />
            </IconButton>
            <Box
                className={clsx({
                    [classes.innerDrawerContainer]: true,
                    [classes.hideInnerDrawer]: !open
                })}>
                {/* <Box className={classes.root}> */}
                <Box className={classes.logo}></Box>
                <Box style={{ width: 240, paddingLeft: 20 }}>
                    {menu.map((item) => renderTree(item, 'parent'))}
                </Box>
                <Box flexGrow={1} />
                <Button
                    onClick={handleLogout}
                    style={{
                        width: '100px',
                        marginLeft: '0px',
                        marginBottom: '8px',
                        padding: '6px 20px 6px 20px',
                        backgroundColor: 'lightgray'
                    }}>
                    <LogoutIcon />
                    <Typography style={{ marginLeft: 3 }} variant="h4">
                        Гарах
                    </Typography>
                </Button>
                {/* </Box> */}
            </Box>
        </CustomDrawer>
    );
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    width: closedDrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
});

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                backgroundColor: drawerColor,
                ...openedMixin(theme)
            }
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                backgroundColor: drawerColor,
                ...closedMixin(theme)
            }
        })
    })
);
