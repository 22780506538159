import combineReducers from 'react-combine-reducers';
import auth from './auth';
import systemReducer from './system';
// import olympiadReducer from './olympiad';

const [reducer, initialState] = combineReducers({
    auth,
    system: systemReducer
    // olympiadReducer,
});

export { reducer, initialState };
