import Cookies from 'js-cookie';
import { SHOW_LOADING, CANCEL_LOADING, SET_SELECTED_MENU } from 'context/actions/system';

const initialState = { isLoading: false, selectedMenu: 'supplier' };

const reducer = (state, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                isLoading: true
            };
        case CANCEL_LOADING:
            return { isLoading: false };
        case SET_SELECTED_MENU:
            return { selectedMenu: action.data };
        default:
            return state;
    }
};

const systemReducer = [reducer, initialState];

export default systemReducer;
