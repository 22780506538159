import React, { StrictMode, useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Image from 'next/image';

import { CacheProvider } from '@emotion/react';
import { ContextState, GlobalContext } from '/context';
import { Box } from '@mui/material';

import HomeLayout from '../layouts/HomeLayout';
import theme from 'styles/theme';
import '../styles/globals.css';

import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import loadingFig from '/public/loading.gif';

import createEmotionCache from '../utils/createEmotionCache';

import ProgressBar from '@badrap/bar-of-progress';

const progress = new ProgressBar({
    size: 3,
    color: '#373A46',
    className: 'bar-of-progress',
    delay: 100
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
    const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;
    const Layout = Component.Layout || HomeLayout;

    return (
        <StrictMode>
            <React.Fragment>
                <CacheProvider value={emotionCache}>
                    <Head>
                        <title>Upart</title>
                        <link rel="shortcut icon" href="/svg/Union.svg" />
                        <link
                            href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
                            rel="stylesheet"
                        />
                        <meta charSet="utf-8" />
                        <meta property="og:title" content="Upart" key="title" />
                        <meta property="og:image" content="/svg/Union.svg" key="ogimage" />
                    </Head>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <ContextState>
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                            <Loader />
                        </ContextState>
                    </ThemeProvider>
                </CacheProvider>
            </React.Fragment>
        </StrictMode>
    );
}

const Loader = () => {
    const { state, dispatch } = useContext(GlobalContext);
    return (
        <>
            {state.system.isLoading && (
                <Box
                    style={{
                        // backgroundColor: 'black',
                        display: 'flex',
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 99999,
                        // opacity: 0.1,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Image
                        // className={styles.Image}
                        // loader={() => img}
                        src={loadingFig}
                        alt=""
                        width={80}
                        height={80}
                        // layout="fixed"
                        // style={{ position: 'absolute', zIndex: 999999, top: 0, left: 0 }}
                    />
                </Box>
            )}
        </>
    );
};

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
    emotionCache: PropTypes.object
};
