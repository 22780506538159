import React from 'react';

import { Box } from '@mui/material';

// components
import LeftMenu from 'components/local/layout/LeftMenu';

export default function MainLayout({ children }) {
    return (
        <div
            style={{
                background: '#f6f6f6',
                display: 'flex',
                flex: 1,
                width: '100%',
                height: '100vh'
            }}>
            <LeftMenu />
            <Box
                component={'main'}
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    backgroundColor: 'gray',
                    overflow: 'hidden'
                }}>
                {children}
            </Box>
        </div>
    );
}
