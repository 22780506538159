export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

import { post, put } from 'utils/fetchRequest';

export const login = async (dispatch, data) => {
    try {
        const result = await post('accounts/web/login', data);
        if (result.success) dispatch({ type: LOGIN_SUCCESS, data: result.data });
        else dispatch({ type: LOGIN_ERROR });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const logout = (dispatch) => {
    dispatch({ type: LOGOUT });
};
