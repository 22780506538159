import React from 'react';
import { Box } from '@mui/material';

export default function ArrowClose(props) {
    return (
        <Box
            style={{
                width: 10,
                display: 'flex',
                height: 24,
                marginLeft: 10,
                alignItems: 'center'
            }}>
            <svg
                width="10"
                height="10"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 13L7 7L1 1"
                    stroke={props.color}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
}
ArrowClose.defaultProps = {
    color: '#BCBCBC'
};
